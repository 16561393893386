exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-childrens-privacy-policy-js": () => import("./../../../src/pages/childrens-privacy-policy.js" /* webpackChunkName: "component---src-pages-childrens-privacy-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-request-password-reset-js": () => import("./../../../src/pages/request-password-reset.js" /* webpackChunkName: "component---src-pages-request-password-reset-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset_password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-teachers-schools-js": () => import("./../../../src/pages/teachers-schools.js" /* webpackChunkName: "component---src-pages-teachers-schools-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-terms-policies-js": () => import("./../../../src/pages/terms-policies.js" /* webpackChunkName: "component---src-pages-terms-policies-js" */)
}

